import _ from "lodash";
import {
  WebglMessagePost,
  WebglMessageResponse,
  WebglMessageActionsPost,
  WebglSlide,
  WebglQuiz,
  WebglIntake,
  WebglNavigation,
} from "@/models/webgl";
import { ModuleMediaTypes, ModuleTextToSpeechLayerItem } from "@/models/module";
import { ModuleQuizSlide } from "@/models/moduleQuiz";
import { ModuleIntakeQuestion } from "@/models/moduleIntake";
import { ModuleNavigation } from "@/models/moduleNavigation";
import { ProjectAvatar } from "@/models/project";
import { useEvent } from "./event";
import { useStorage } from "./storage";
import { inject, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { detect } from "detect-browser";
import { useTextToSpeech } from "./textToSpeech";
import { SlideCustomAnimations } from "@/models/slide";

const browser = detect();

const audioMode = ref(false);
const initialized = ref(false);
const agentLoaded = ref(false);
const animationLoaded = ref(false);
const slideReady = ref(false);
const audioState = ref({
  duration: 0,
  currentTime: 0 as number,
  playing: false,
  canResumeAudio: false,
});
const avatar = ref<ProjectAvatar>();
const agentType = ref("");
const animationsList = ref<
  Array<{
    text: string;
    value: string;
    duration: number;
    offset: number;
  }>
>([]);
const customAnimations = ref<Array<SlideCustomAnimations>>([]);

const evtHandler: {
  [action: string]: (data: any) => void;
} = {};

let queue: Array<WebglMessagePost> = [];
let currentSlide: WebglMessagePost | null = null;
let audioEl: HTMLAudioElement;
let isFirstSlideReady = true;
let currentTimeInteval: any = null;

export { audioState };

export const useWebgl = () => {
  const events = inject("events") as EventsEnum;

  const eventComposable = useEvent();
  const textToSpeechComposable = useTextToSpeech();

  function init() {
    if (browser && browser.version) {
      const browserVersion = +browser.version.split(".")[0];
      audioMode.value = browser.name === "safari" && browserVersion <= 13;
    }

    if (!audioMode.value) startEventHandler();
  }

  function loadAudio() {
    return new Promise((resolve) => {
      if (
        !textToSpeechComposable.text.value ||
        !textToSpeechComposable.avatarVoice.value
      ) {
        resolve(false);
        return;
      }
      const audioUrl = textToSpeechComposable.avatarVoice.value.soundClipURL;

      if (audioEl && audioEl.src === audioUrl) {
        resolve(true);
        return;
      }

      if (audioEl) {
        resetSlideInfo();
        audioEl.setAttribute("src", audioUrl);
        audioEl.load();
      } else {
        audioEl = new Audio(audioUrl);
      }

      audioEl.oncanplay = () => {
        if (
          !audioState.value.duration &&
          audioEl &&
          audioEl.duration != audioState.value.duration
        ) {
          audioState.value.duration = calcDuration(audioEl.duration);
          slideReady.value = true;
          agentLoaded.value = true;
          initialized.value = true;
          resolve(true);
        }
      };

      audioEl.onplay = () => {
        audioState.value.playing = true;
      };

      audioEl.onpause = () => {
        audioState.value.playing = false;
      };
    });
  }

  function startUpdateCurrentTime() {
    currentTimeInteval = setInterval(() => {
      audioState.value.currentTime += 0.1;
      audioState.value.currentTime = Number(
        audioState.value.currentTime.toFixed(2)
      );
      if (audioState.value.currentTime >= audioState.value.duration) {
        stopUpdateCurrentTime();
        resetSlideInfo();
        pause();
        post({ action: "clearChalkboard" });
        reloadCurrentSlide();
      }

      eventComposable.emit(
        events.WEBGL_UPDATE_TIME,
        audioState.value.currentTime
      );
    }, 100);
  }

  function stopUpdateCurrentTime() {
    if (currentTimeInteval) clearInterval(currentTimeInteval);
    currentTimeInteval = null;
  }

  function startEventHandler() {
    evtHandler.initialized = () => {
      initialized.value = true;
    };
    const userLang = useStorage().get("language");
    evtHandler.agentLoaded = () => {
      agentLoaded.value = true;
      _.forEach(queue, (message) => post(message));
      queue = [];
      post({
        action: "getAvailableNBLGAnimations",
        data: {
          language: userLang,
        },
      });
      post({
        action: "enableUtteranceTimeEvent",
        data: {
          enabled: true,
        },
      });
      reloadCurrentSlide();
    };
    evtHandler.waitingForInitializationMessage = () => {
      post({ action: "startDynamicMode" });
    };
    evtHandler.slideReady = (data) => {
      if (!data.duration) data.duration = 0;
      slideReady.value = true;
      audioState.value.duration = calcDuration(data.duration || 0);
      eventComposable.emit(
        events.TEXT_TO_SPEECH_SLIDE_READY,
        isFirstSlideReady
      );
      isFirstSlideReady = false;
    };
    evtHandler.availableNBLGAnimations = (data) => {
      animationsList.value = [];
      _.forEach(data, (animation) => {
        animationsList.value.push({
          text: animation.label ? animation.label : animation.name,
          value: animation.name,
          duration: animation.animDuration * 1000,
          offset: animation.offset * 1000,
        });
      });

      setAnimationLayersDuration();
    };
  }

  function show(divId?: string, zIndex?: number) {
    eventComposable.emit(events.WEBGL_SHOW, { divId, zIndex });
  }

  function hide() {
    pause();
    post({ action: "clearChalkboard" });
    const evtToClear: Array<WebglMessageActionsPost> = [
      "slidePaused",
      "quizPaused",
      "intakePaused",
      "navigationPaused",
    ];
    _.forEach(evtToClear, (evt) => removeFromQueue(evt));
    currentSlide = null;
    resetSlideInfo();
    eventComposable.emit(events.WEBGL_HIDE);
  }

  function pause() {
    stopUpdateCurrentTime();
    if (audioMode.value) {
      if (audioEl) audioEl.pause();
      return;
    } else if (audioState.value.playing) {
      audioState.value.playing = false;
      post({ action: "pauseSlide" });
    }
  }

  function play() {
    if (audioMode.value) {
      if (audioEl) audioEl.play();
      return;
    }
    const canResumeAudio = !!audioState.value.canResumeAudio;
    const currentTime = _.cloneDeep(audioState.value.currentTime);
    const isTimeChanged = currentTime > 0;

    audioState.value.playing = true;
    post({
      action: canResumeAudio ? "resumeSlide" : "playSlide",
    });

    if (isTimeChanged) {
      if (canResumeAudio) {
        seek(currentTime);
      } else {
        evtHandler.slideAudioStarted = () => {
          seek(currentTime);
          delete evtHandler.slideAudioStarted;
        };
      }
    }

    audioState.value.canResumeAudio = true;

    startUpdateCurrentTime();
  }

  function toggleAudio() {
    if (audioState.value.playing) {
      pause();
    } else {
      play();
    }
  }

  function getAnimationByName(name: string) {
    return _.find(
      animationsList.value,
      (animation) => animation.value === name
    );
  }

  function setCurrentTime(seconds: number) {
    audioState.value.currentTime = seconds;

    if (audioMode.value) {
      if (audioEl) {
        audioEl.currentTime = seconds;
        audioState.value.playing = false;
        pause();
        return;
      }
    }
    if (audioState.value.playing) {
      seek(seconds);
    }
  }

  function onReceiveEvt(message: WebglMessageResponse) {
    if (evtHandler[message.action]) {
      evtHandler[message.action](message.data);
    }
  }

  async function post(message: WebglMessagePost) {
    const isInicializationEvt = [
      "startDynamicMode",
      "getAvailableClothes",
      "loadAgent",
    ].includes(message.action);

    const isSlideEvt = [
      "slidePaused",
      "quizPaused",
      "intakePaused",
      "navigationPaused",
    ].includes(message.action);

    if (isSlideEvt) currentSlide = _.cloneDeep(message);

    if (!isInicializationEvt) {
      if (!initialized.value || !agentLoaded.value) {
        removeFromQueue(message.action);
        queue.push(message);
        return;
      }
    } else {
      agentLoaded.value = false;
    }

    eventComposable.emit(events.WEBGL_POST, _.cloneDeep(message));
  }

  function getPresets(_agentName: string) {
    const agentName = _agentName.includes("_")
      ? _agentName.split("_")[1].toLowerCase()
      : _agentName.toLowerCase();

    const clothes: { [agent: string]: { [preset: string]: Array<string> } } = {
      john: {
        default: ["glasses", "shoes", "labcoat", "belt", "slacks", "shirt"],
      },
      allison: {
        default: ["shoes", "hair", "pants", "shirt", "labcoat"],
      },
      andy: {
        default: ["hair", "shirt", "shoes", "labcoat", "necktie", "pants"],
      },
      peter: {
        default: [
          "labcoat",
          "glasses",
          "shoes",
          "belt",
          "slacks",
          "shirt",
          "pompador",
        ],
      },
      hanna: {
        default: ["hannahair", "blouse", "loafers", "labcoat", "pants"],
      },
      noriko: {
        default: ["blouse", "hairbob", "loafers", "labcoat", "pants"],
      },
    };

    if (!clothes[agentName]) return null;

    return clothes[agentName];
  }

  function filterClothsByAgent(
    _agentName: string,
    clothes: Array<string>,
    preset = "default"
  ) {
    const agentPresets = getPresets(_agentName);

    let availableClothes: Array<string> = [];

    if (agentPresets) {
      if (agentPresets[preset]) {
        availableClothes = agentPresets[preset];
      } else {
        availableClothes = agentPresets.default;
      }
    }

    return _.filter(
      clothes,
      (clothe) =>
        !!_.find(availableClothes, (val) =>
          clothe.toLowerCase().includes(val.toLowerCase())
        )
    );
  }

  function removeFromQueue(_action: WebglMessageActionsPost) {
    queue = _.filter(queue, (message) => {
      const action = typeof message === "object" ? message.action : message;
      return _action != action;
    });
  }

  function seek(time: number) {
    post({
      action: "seek",
      data: { time },
    });
  }

  function loadAvatar(_avatar: ProjectAvatar, _force = false, preset?: string) {
    if (
      !_force &&
      avatar.value &&
      _avatar.avatarName === avatar.value.avatarName
    ) {
      return;
    }
    avatar.value = _avatar;
    agentType.value = avatar.value.prefabName.split("_")[0];
    agentLoaded.value = false;
    if (!initialized.value) {
      evtHandler.initialized = () => {
        initialized.value = true;
        loadAvatar(_avatar, true, preset);
      };
      return;
    }

    setTimeout(() => {
      const agentName = _avatar.prefabName;

      evtHandler.availableClothes = (clothesArr: Array<string>) => {
        const clothes: Array<object> = [];
        let bodySettings: Array<object> = [];
        if (agentName.toLowerCase().includes("peter")) {
          bodySettings = [
            {
              itemName: "DHM_Peter_1",
              meshName: "DHM_Peter_Body_GEO",
              materialIndex: "0",
              type: 4,
              name: "_MainTex",
              value:
                "https://qahiastorage.blob.core.windows.net/uploaded-assets/Textures/DHM_WhiteBaseMesh_UV_DHM_White_Body_MAT_BaseMap.png",
            },
            {
              itemName: "DHM_Pompador_1",
              meshName: "DHM_Pompador_GEO",
              materialIndex: "0",
              type: 0,
              name: "_Color",
              value: "D4D5DBFF",
            },
            {
              itemName: "DHM_Pompador_1",
              meshName: "DHM_Pompador_GEO",
              materialIndex: "0",
              type: 4,
              name: "_MainTex",
              value:
                "https://qahiastorage.blob.core.windows.net/uploaded-assets/Textures/DHM_pomphair_Lo_initialShadingGroup_BaseMap.png",
            },
          ];
        }
        _.forEach(
          filterClothsByAgent(agentName, clothesArr, preset),
          (itemAddress) => {
            const sliptItemAdress = itemAddress.split("_");
            sliptItemAdress[0] = agentType.value;
            clothes.push({
              itemAddress: sliptItemAdress.join("_"),
              settings: [],
            });
          }
        );

        post({
          action: "loadAgent",
          data: {
            agentConfig: {
              agentName,
              bodySettings,
              clothes,
            },
            voiceName: _avatar.voice,
            voiceSystem: _avatar.voiceService,
          },
        });
      };

      post({
        action: "getAvailableClothes",
        data: {
          agentType: agentType.value,
        },
      });
    }, 3000);
  }

  async function loadSlide(
    layers: Array<ModuleTextToSpeechLayerItem>,
    showCme: boolean,
    mediaAttachedUrl?: string,
    mediaAttachedType?: ModuleMediaTypes | null
  ) {
    await getAvatarVoice();
    if (audioMode.value) {
      await loadAudio();
      return;
    }

    const slideObj: WebglSlide = {
      id: uuidv4(),
      soundData: textToSpeechComposable.avatarVoice.value,
      pauses: null,
      isConversationEnd: false,
      cardData: {
        data: [],
      },
      customData: null,
      showCme,
    };

    const orderedLayers = _.orderBy(
      _.filter(layers, (l) => l.type === "media"),
      "wordIndex",
      "asc"
    );
    const firstMediaLayer = orderedLayers[0];
    if (mediaAttachedUrl) {
      const isVideo =
        mediaAttachedType === "video" || mediaAttachedType === "Video";
      const obj = {
        type: isVideo ? 1 : 0,
        showAt: 0,
        hideAt:
          firstMediaLayer && !!textToSpeechComposable.avatarVoice.value
            ? textToSpeechComposable.avatarVoice.value.timeIndexes[
                firstMediaLayer.wordIndex
              ].time / 1000
            : 1000,
      };

      if (isVideo) {
        slideObj.cardData.data.push({
          ...obj,
          ...{
            videoURL: mediaAttachedUrl,
            videoPauses: [],
          },
        });
      } else {
        slideObj.cardData.data.push({
          ...obj,
          ...{
            imageURL: mediaAttachedUrl,
            clickAction: null,
          },
        });
      }
    }

    const avatarVoice = textToSpeechComposable.avatarVoice.value;

    _.forEach(orderedLayers, (layer, index) => {
      if (!layer.meta.media) return;
      if (!index) slideObj.cardData.data = [];
      let showAtSec = 0;
      let hideAtSec = 1;

      const timeIndex = avatarVoice
        ? avatarVoice.timeIndexes[layer.wordIndex]
        : null;

      if (timeIndex && avatarVoice) {
        const lastTimeIndex =
          avatarVoice.timeIndexes[avatarVoice.timeIndexes.length - 1];

        showAtSec = timeIndex.time;
        hideAtSec = lastTimeIndex.time;

        const nextLayer = orderedLayers[index + 1];
        if (nextLayer) {
          const nextTimeindex = avatarVoice.timeIndexes[nextLayer.wordIndex];

          if (nextTimeindex) {
            hideAtSec = nextTimeindex.time;
            if (
              layer.meta.media.type === "video" &&
              layer.meta.media.duration &&
              hideAtSec > showAtSec + layer.meta.media.duration
            ) {
              hideAtSec = showAtSec + layer.meta.media.duration;
            }
          }
        } else {
          const toAdd = layer.meta.media.duration
            ? layer.meta.media.duration
            : 10000;
          hideAtSec += toAdd;
        }

        showAtSec = showAtSec / 1000;
        hideAtSec = hideAtSec / 1000;
      }

      const isImage = layer.meta.media.type === "image";

      const obj = {
        type: isImage ? 0 : 1,
        showAt: showAtSec,
        hideAt: hideAtSec,
      };

      if (isImage) {
        slideObj.cardData.data.push({
          ...obj,
          ...{
            imageURL: layer.meta.media.url,
            clickAction: layer.meta.media.link,
            showFullscreen: layer.meta.media.fullscreen,
          },
        });
      } else {
        slideObj.cardData.data.push({
          ...obj,
          ...{
            videoURL: layer.meta.media.url,
            videoPauses: [],
            showFullscreen: layer.meta.media.fullscreen,
          },
        });
      }
    });

    post({
      action: "slidePaused",
      data: slideObj,
    });
  }

  async function loadQuiz(slide: ModuleQuizSlide, individualOutros: boolean) {
    await getAvatarVoice();
    if (audioMode.value) {
      await loadAudio();
      return;
    }
    const quizObj: WebglQuiz = {
      question: {
        uiText: slide.question.question_chr || "",
        answers: [],
        utteranceText: textToSpeechComposable.ssml.value,
        allowMultiple: slide.question.allowMultiple,
        showCme: slide.showCme,
      },
      responses: [],
      defaultResponse: "",
    };

    _.forEach(slide.answers, (answer, index) => {
      quizObj.question.answers.push({
        uiText: answer.answer_chr || "",
      });

      if (individualOutros) {
        quizObj.responses.push({
          trigger: [index],
          utteranceText: textToSpeechComposable.generateSSML(
            answer.outro.outroLine_chr,
            answer.outro._layers
          ),
        });
      }
    });

    if (!individualOutros) {
      const correctAnswerIndex = _.findIndex(
        slide.answers,
        (answer) => answer.isCorrect
      );

      const wrongAnswerIndex = _.findIndex(
        slide.answers,
        (answer) => !answer.isCorrect
      );

      quizObj.responses.push({
        trigger: [correctAnswerIndex],
        utteranceText: textToSpeechComposable.generateSSML(
          slide.answers[correctAnswerIndex].outro.outroLine_chr,
          slide.answers[correctAnswerIndex].outro._layers
        ),
      });

      quizObj.responses.push({
        trigger: _.filter(
          _.map(slide.answers, (value, index) => index),
          (_index) => _index != correctAnswerIndex
        ),
        utteranceText: textToSpeechComposable.generateSSML(
          slide.answers[wrongAnswerIndex].outro.outroLine_chr,
          slide.answers[wrongAnswerIndex].outro._layers
        ),
      });
    }

    post({
      action: "quizPaused",
      data: quizObj,
    });
  }

  async function loadIntake(intakeQuestion: ModuleIntakeQuestion) {
    await getAvatarVoice();
    if (audioMode.value) {
      await loadAudio();
      return;
    }
    const intakeTypes = [
      "Two_Choice",
      "Number_Range",
      "Upload_Media",
      "Multiple_Choice",
      "Text_Entry",
    ];
    const intakeObj: WebglIntake = {
      uiText: intakeQuestion.title || "",
      utterance: {
        id: uuidv4(),
        soundData: textToSpeechComposable.avatarVoice.value,
        pauses: null,
        isConversationEnd: false,
        cardData: {
          data: [],
        },
        customData: null,
        showCme: true,
      },
      isRequired: true,
      type: _.findIndex(
        intakeTypes,
        (type) => type === intakeQuestion.questionType
      ),
    };

    if (intakeQuestion.binaryQ) {
      intakeObj.uiAnswer1 = intakeQuestion.binaryQ.option1 || "";
      intakeObj.uiAnswer2 = intakeQuestion.binaryQ.option2 || "";
    } else if (intakeQuestion.rangeQ) {
      intakeObj.range = {
        x: intakeQuestion.rangeQ.min,
        y: intakeQuestion.rangeQ.max,
      };
      intakeObj.isNaturalNumbers = intakeQuestion.rangeQ.isNaturalNumbers;
    } else if (intakeQuestion.imageQ) {
      intakeObj.imageURL = intakeQuestion.imageQ.imageURL || "";
      intakeObj.checkmarkURL = intakeQuestion.imageQ.checkmarkURL || "";
    } else if (intakeQuestion.mcqQ) {
      intakeObj.answers = _.cloneDeep(intakeQuestion.mcqQ.options) || [];
    }

    post({
      action: "intakePaused",
      data: intakeObj,
    });
  }

  async function loadNavigation(navigationModule: ModuleNavigation) {
    await getAvatarVoice();
    if (audioMode.value) {
      await loadAudio();
      return;
    }
    const navigationObj: WebglNavigation = {
      uiText: navigationModule.utterance.line_chr,
      utterance: {
        id: uuidv4(),
        soundData: textToSpeechComposable.avatarVoice.value,
        pauses: null,
        isConversationEnd: false,
        cardData: {
          data: [],
        },
        customData: null,
        showCme: navigationModule.showCme,
      },
      navigationPoints: _.map(navigationModule.navigationButtons, (button) => {
        return {
          moduleId: uuidv4(),
          index: 0,
          uiText: button.buttonLabel,
          type: 0,
        };
      }),
      type: 4,
      isSkippable: true,
      isReplayable: true,
      allowPrevious: true,
      canAskQuestions: true,
    };

    post({
      action: "navigationPaused",
      data: navigationObj,
    });
  }

  function resetSlideInfo() {
    isFirstSlideReady = true;
    slideReady.value = false;
    audioState.value.duration = 0;
    audioState.value.currentTime = 0;
    audioState.value.playing = false;
    audioState.value.canResumeAudio = false;
  }

  function animationPreview(animation: string) {
    post({
      action: "playNBLGAnimation",
      data: {
        name: animation,
      },
    });
  }

  async function reloadCurrentSlide() {
    if (!!currentSlide && currentSlide.action != "quizPaused") {
      await getAvatarVoice(true);
    }
    if (!textToSpeechComposable.avatarVoice.value || !currentSlide) return;
    if (currentSlide.action == "slidePaused") {
      const data = currentSlide.data as WebglSlide;
      data.soundData = textToSpeechComposable.avatarVoice.value;
    } else {
      const data = currentSlide.data as WebglNavigation | WebglIntake;
      data.utterance.soundData = textToSpeechComposable.avatarVoice.value;
    }
    post(currentSlide);
  }

  async function getAvatarVoice(force = false) {
    pause();
    slideReady.value = false;
    audioState.value.currentTime = 0;
    audioState.value.canResumeAudio = false;

    const _customAnimations: Array<SlideCustomAnimations> = [];

    _.forEach(textToSpeechComposable.layers.value, (layer) => {
      if (!layer.meta.animation) return;
      _customAnimations.push({
        AnimationName: layer.meta.animation.name,
        Word: layer.word,
        WordIndex: layer.wordIndex,
        TimingOffset: 0,
      });
    });

    if (
      JSON.stringify(customAnimations.value) !=
      JSON.stringify(_customAnimations)
    ) {
      customAnimations.value = _customAnimations;
      force = true;
    }

    await textToSpeechComposable.getAvatarVoice(
      force,
      avatar.value,
      customAnimations.value
    );
  }

  function setCmeVisibility(val: boolean) {
    post({
      action: "setCMeVisibility",
      data: {
        enabled: val,
      },
    });
  }

  function calcDuration(duration: number) {
    let newDuration = duration;

    const mediaLayers = _.filter(
      _.orderBy(textToSpeechComposable.layers.value, "wordIndex", "desc"),
      (l) => l.type === "media"
    );

    if (mediaLayers) {
      const lastMedia = mediaLayers[0];
      if (
        lastMedia &&
        lastMedia.meta.media &&
        lastMedia.meta.media.type === "video" &&
        lastMedia.meta.media.duration
      ) {
        if (textToSpeechComposable.avatarVoice.value) {
          const timeIndex =
            textToSpeechComposable.avatarVoice.value.timeIndexes[
              lastMedia.wordIndex
            ];

          const videoEndsAt =
            (timeIndex ? timeIndex.time / 1000 : 0) +
            lastMedia.meta.media.duration / 1000;

          if (videoEndsAt > newDuration) {
            newDuration = videoEndsAt;
          }
        } else {
          newDuration = lastMedia.meta.media.duration / 1000;
        }
      }
    }

    return newDuration;
  }

  function setAnimationLayersDuration() {
    _.forEach(textToSpeechComposable.layers.value, (layer) => {
      if (layer.type === "animation" && layer.meta.animation) {
        const animation = getAnimationByName(layer.meta.animation.name);
        if (animation) {
          layer.meta.animation.duration = animation.duration;
          layer.meta.animation.timelineDuration = animation.duration;
          layer.meta.animation.offset = animation.offset;
        }
        animationLoaded.value = true;
      }
    });
  }

  return {
    init,
    show,
    hide,
    post,
    pause,
    play,
    onReceiveEvt,
    loadSlide,
    loadAvatar,
    loadQuiz,
    loadIntake,
    loadNavigation,
    removeFromQueue,
    toggleAudio,
    setCurrentTime,
    resetSlideInfo,
    animationPreview,
    reloadCurrentSlide,
    getAnimationByName,
    getPresets,
    setCmeVisibility,
    setAnimationLayersDuration,
    animationLoaded,
    initialized,
    agentLoaded,
    slideReady,
    audioState,
    avatar,
    animationsList,
    audioMode,
    evtHandler,
  };
};
